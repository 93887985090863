<template>
  <div>
    <v-container grid-list-lg fluid>
        <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
            <v-layout row wrap>
                <v-flex lg4 md12 sm12 pr-6 pl-lg-6>
                    <v-layout row wrap justify-end>
                        <v-menu
                            v-model="menuStart"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="computedStartDateFormatted"
                                label="Từ ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.startDate" no-title @input="menuStart = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md12 sm12 pr-6>
                    <v-layout row wrap justify-end>
                        <v-menu
                            v-model="menuEnd"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="computedEndDateFormatted"
                                label="Đến ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.endDate" no-title @input="menuEnd = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md12 sm12 pr-6>
                    <v-btn @click="filter_data" color="#a77800" outlined>Xem</v-btn>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
                <v-flex lg3 md12 sm12 pr-6 pl-lg-6>
                    <v-select clearable :items="puchase_staffs" item-value="ID" item-text="FullName" v-model="selectedBuyerId" label="CSKH"></v-select>
                </v-flex>
                <v-flex lg3 md12 sm12 pr-6>
                    <v-select clearable :items="cs_staffs" item-value="ID" item-text="FullName" v-model="selectedCSId" label="Tiếng Trung"></v-select>
                </v-flex>
                <v-flex lg3 md12 sm12 pr-6>
                    <v-select clearable :items="staffs" item-value="ID" item-text="FullName" v-model="selectedSaleId" label="Sale"></v-select>
                </v-flex>
                <v-flex lg3 md12 sm12 pr-6>
                    <v-select :items="orderStatus" item-value="value" item-text="name" v-model="selectedStatus" label="Trạng thái đơn"></v-select>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg12 md12 sm12 xs12>
            <v-layout row wrap>
                <v-flex lg12 md12 sm12 xs12>
                    <highcharts :options="chartOptions"></highcharts>
                </v-flex>
            </v-layout>
        </v-flex>
        
        <v-flex lg12>
            <v-card class="mb-8">
                <v-data-table fixed-header height="750px"   :headers="headers" hide-default-footer :sortable="false" :items="items" :server-items-length="items ? items.length : 0" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{item.ReportDate.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$3-$2-$1")}}</td>
                            <td>{{ item.Username }}</td>
                            <td class="text-end">{{ item.TotalFeeBuy | currency}}</td>
                            <td class="text-end">{{ item.TotalFeeOther | currency}}</td>
                            <td class="text-end">{{ item.TotalFeeWeight | currency}}</td>
                            <td class="text-end">{{ item.TotalFeeWeightValue | currency}}</td>
                            <td class="text-end">{{ item.TotalProductAmount | currency}}</td>
                            <td class="text-end">{{ item.TotalBuyRevenue | currency}}</td>
                            <td class="text-end">{{ item.TotalExchangeRevenue | currency}}</td>
                            <td class="text-end">{{ item.TotalDiscount | currency}}</td>
                            <td class="text-end">{{ item.TotalRevenue | currency}}</td>
                        </tr>
                    </template>
                    <template v-slot:body.append>
                        <tr class="table-summary">
                            <td class="font-weight-bold" colspan="2">TỔNG</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalFeeBuy | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalFeeOther | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalFeeWeight | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalFeeWeightValue | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalProductAmount | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalBuyRevenue | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalExchangeRevenue | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalDiscount | currency}}</td>
                            <td class="font-weight-bold text-end">{{ summary.TotalRevenue | currency}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import {Chart} from 'highcharts-vue';
import { mapState } from "vuex";
import configs from "@/configs";
import _ from "lodash";
import moment from "moment"
export default {
    components: {
        highcharts: Chart 
    },
    data() {
        return {
            viewName: "bao-cao-loi-nhuan-khach-hang",
            dateRange: {
                startDate: moment.utc(Date.now()).startOf('month').toISOString().substr(0, 10),
                endDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
            },
            menuStart: false,
            menuEnd: false,
            orderStatus: configs.ORDER_STATUS,
            selectedStatus: 10,
            selectedBuyerId: 0,
            selectedCSId: 0,
            selectedSaleId: 0,
            headers: [
                {
                text: "Ngày",
                value: "ReportDate",
                sortable: false,
                filterable: true,
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Khách hàng",
                value: "Username",
                sortable: false,
                filterable: true,
                dataType: configs.DATA_TYPE["String"]
                },
                {
                text: "Phí dịch vụ",
                value: "TotalFeeBuy",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Phí khác",
                value: "TotalFeeOther",
                sortable: false,
                filterable: true,
                align: "end",
                values: configs.DATA_TYPE["Number"]
                },
                {
                text: "Doanh thu vận chuyển",
                value: "TotalFeeWeight",
                sortable: false,
                filterable: true,
                align: "end",
                values: configs.DATA_TYPE["Number"]
                },
                {
                text: "Lợi nhuận vận chuyển",
                value: "TotalFeeWeightValue",
                sortable: false,
                filterable: true,
                align: "end",
                values: configs.DATA_TYPE["Number"]
                },
                {
                text: "Tổng tiền báo giá",
                value: "TotalProductAmount",
                sortable: false,
                filterable: true,
                align: "end",
                values: configs.DATA_TYPE["Number"]
                },
                {
                text: "Lợi nhuận mua hàng",
                value: "TotalBuyRevenue",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Lợi nhuận tệ",
                value: "TotalExchangeRevenue",
                sortable: false,
                filterable: true,
                align: "end",
                values: configs.DATA_TYPE["Number"]
                },
                {
                text: "Tổng chiết khấu",
                value: "TotalDiscount",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "Tổng lợi nhuận",
                value: "TotalRevenue",
                sortable: false,
                filterable: true,
                align: "end",
                dataType: configs.DATA_TYPE["Number"]
                }                
            ]
        };
    },
    computed: {
        ...mapState({
            items: state => state.report.customerReport.data,
            loading: state => state.report.loading,
            staffs: state => state.commons.all_users,
            puchase_staffs: state =>
                _.filter(state.commons.all_users, function(o) {
                return (o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
            }),
            cs_staffs: state =>
                _.filter(state.commons.all_users, function(o) {
                return (o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
            })
        }),
        computedStartDateFormatted () {
            return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
        },
        computedEndDateFormatted () {
            return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        summary() {
            var ret = {
                'TotalFeeBuy': _.sumBy(this.items, 'TotalFeeBuy'),
                'TotalFeeOther': _.sumBy(this.items, 'TotalFeeOther'),
                'TotalFeeWeight': _.sumBy(this.items, 'TotalFeeWeight'),
                'TotalFeeWeightValue': _.sumBy(this.items, 'TotalFeeWeightValue'),
                'TotalExchangeRevenue': _.sumBy(this.items, 'TotalExchangeRevenue'),
                'TotalProductAmount': _.sumBy(this.items, 'TotalProductAmount'),
                'TotalBuyRevenue': _.sumBy(this.items, 'TotalBuyRevenue'),
                'TotalDiscount': _.sumBy(this.items, 'TotalDiscount'),
                'TotalRevenue': _.sumBy(this.items, 'TotalRevenue'),
            };
            return ret;
        },
        chartOptions() {
            var dataChart =  _.sortBy(this.items,'ReportDate');
            var ret = {
                chart: { type: 'line' },
                title: { text: 'Tổng doanh thu' },
                credits: { enabled: false },
                tooltip: {shared: true},
                xAxis: {
                    categories: _.map(dataChart, function(x) {return x.ReportDate.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$3-$2-$1")})
                },
                yAxis: {
                    title: {
                        text: 'Tổng tiền (VNĐ)'
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    { 
                        name: 'Phí dịch vụ',
                        data: _.map(dataChart, 'TotalFeeBuy')
                    },
                    { 
                        name: 'Phí khác',
                        data: _.map(dataChart, 'TotalFeeOther')
                    },
                    { 
                        name: 'Phí vận chuyển',
                        data: _.map(dataChart, 'TotalFeeWeight')
                    },
                    { 
                        name: 'Lợi nhuận tệ',
                        data: _.map(dataChart, 'TotalExchangeRevenue')
                    },
                    { 
                        name: 'Lợi nhuận mua hàng',
                        data: _.map(dataChart, 'TotalBuyRevenue')
                    },
                    { 
                        name: 'Chiết khấu',
                        data: _.map(dataChart, 'TotalDiscount')
                    },
                    { 
                        name: 'Tổng doanh thu',
                        data:  _.map(dataChart, 'TotalRevenue')
                    }
                ]
            };

            return ret;
        }
    },
    methods: {
        filter_data: function () {
            this.$store.dispatch("commons/getAllUsers");
            var param = {
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
                buyerid: this.selectedBuyerId | 0,
                csid: this.selectedCSId | 0,
                saleid: this.selectedSaleId | 0,
                status: this.selectedStatus
            };
            this.$store.dispatch("report/getCustomerReport", param);
        }
    },
    mounted() {
        this.filter_data();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>
<style scoped>
.rate-p {
    margin-bottom: 0px;
    margin-top: 16px;
}
.rate-p2 {
    margin-bottom: 16px;
    margin-top: 8px;
    color: rgb(168, 168, 168);
}
</style>